.analytics-drop {
    .css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        background: none !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .css-1xnbq41-MuiAutocomplete-root {
        min-width: 200px !important;
    }
}