// * COLORS

$black1: #000000;
$black2: #0e0d21; // * SURFACE DARK BLUE ( BACKGROUND COLOR)
$black3: #110f26;
$black4: #191c24;
$black5: #0f1015;
$black6: #1c1f39;
$black7: #1a1b30;
$black8: #191732;
$black8: #202837;
$black9: #15142a; //* INPUT BACKGROUND COLOR
$black10: #27283f;
$black11: #100f24;
$black12: #14112a;
$black13: #1a1932; // * SURFACE 2 COLOR (POPOVERS & PAPERS) & BUTTON GREY COLOR
$black14: #1c1b33;
$black15: #14122b; // * SURFACE 1 COLOR (CARDS & TABLES)
$black16: #211f3c; // * HOVER COLOR & SECONDARY COLOR
$black17: #252442;
$black18: #2b2a46; //* HOVER MENU ITEM COLORS
$black19: #1e1e3b; //* HOVER MENU ITEM COLORS
$black20: #252441; //* SURFACE 3 COLOR
// $black21: rgba(39, 40, 63, 0.5);
$black21: rgba(176, 189, 227, 0.05);
$black22: #06051a;

$white1: #ffffff;
$grey1: #5b607c;
$grey2: #292d3a;
$grey3: rgb(108, 114, 147);
$grey4: #414c65; // ! DISABLED COLOR 2  NOT USED
$grey5: #38395c; // * BORDER COLORS
$grey6: #5c6d8e; // * DISABLED COLOR
$grey7: #717898;
$grey8: #343e50;
$grey9: #7879a9; // * TEXT EDITOR COLOR
$lightBlue: #c8d8ff; // * Primary Text Color
$blue1: #96abde;
$blue2: #6e8dd7; // * LINK COLORS
$blue3: #666a9b;
$green1: #a6faaf;
$green2: #1ad598;
$green3: #56ca00;
$green4: #a6faaf;
$green5: #19c18c;
$red1: #f67e80;
$red2: #ff6c6f;
$red3: #df6264;
$red4: #f68486;
$red5: #fc756e;
$red6: #f67476;
$purple: #8f5fe8;

$gradient1: linear-gradient(
  303.01deg,
  #5961f8 -4.4%,
  #f2d9f7 111.29%
) !important; //* PRIMARY GRADIENT

$purple1: #8f5fe8;
$yellow1: #ffab00;

$yellow2: #ffc75b;
// $green1: #00d25b;
// $blue1: #0090e7;
// $purple: #8f5fe8;
$blue1: #0d80d8;

// $black1: #000000;
// $black2: #313132;
// $black3: #3e434e;
// $white1: #fff;
// $white2: #fffff7;
// $grey1: #989ba0;
// $grey2: #e5e5e5;

// * FONTS
$font0: 8px;
$font1: 10px;
$font2: 13px;
$font3: 14px;
$font4: 15px;
$font5: 16px;
$font6: 18px;
$font7: 20px;
$font8: 22px;
$font9: 26px;
$font10: 30px;
$font11: 36px;
$font12: 40px;
$font13: 43px;
$font14: 50px;
