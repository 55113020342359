.bg-radiant-store {
    background: radial-gradient(205.89% 122.44% at 95.27% 4.08%, rgba(222, 164, 242, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%) !important;
    border: 2px solid radial-gradient(205.89% 122.44% at 95.27% 4.08%, rgba(222, 164, 242, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.bg-contact {
    background: radial-gradient(205.89% 122.44% at 95.27% 4.08%, rgba(222, 164, 242, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.bg-invite {
    background: radial-gradient(205.89% 122.44% at 95.27% 4.08%, rgba(222, 164, 242, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.bg-button {
    border: 0.754px solid rgba(126, 132, 188, 0.40) !important;
    background: linear-gradient(303deg, rgba(89, 97, 248, 0.10) -4.4%, rgba(242, 217, 247, 0.10) 111.29%);
}

.fit-Content {
    height: fit-content;
}