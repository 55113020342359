@import "../../styles/variables";

.image-gallery-slide-wrapper.bottom {
  aspect-ratio: 1 !important;
  display: grid;
  place-content: center;
  background-color: #1c1b33 !important;
}

.image-gallery-thumbnail.active {
  border: 2px solid #5c6d8e !important;
  border-radius: 5px;
}
.image-gallery-bullet {
  padding: 0px 6px !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0 4px !important;
}

.image-gallery-bullet.active {
  border: 2px solid #000 !important;
}
.image-gallery {
  &-svg {
    height: 60px !important;
    width: 30px !important;
  }
  &-image {
    border-radius: 5px;
  }
  &-thumbnail {
    width: 4rem !important;
    &-image {
      border-radius: 5px;
    }
  }
  &-fullscreen-button {
    padding: 4px;
    .image-gallery-svg {
      height: 40px !important;
      width: 20px !important;
    }
  }
}
