.rst__expandButton {
  //   background: #15142a
  //     url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+)
  //     no-repeat center;
}
.rst__rowContents {
  //   color: #5c6d8e !important;
  color: #c8d8ff !important;
  // background: #15142a;
  background: #1a1932;
  border: 1px solid #38395c;
  border-radius: 0 8px 8px 0;
  box-shadow: none;
}
.rst__rowTitle {
  font-weight: 400;
  font-size: 13px;
}
.rst__moveHandle {
  background: url("../../assets/icons/draggerIcon.svg");
  background-size: 42px;
  border: 1px solid #38395c;
  border-right: 0;
  background-repeat: no-repeat;
  box-shadow: none;
  width: 40px;
  border-radius: 8px 0 0 8px;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: #38395c;
}
