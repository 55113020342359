.theme-hover {
    padding-block: 0.3rem;
    padding-inline: 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;

    &:hover {
        background: #1a1932;
    }
}

.edit-icon {
    display: none;
}

.theme-hover:hover .edit-icon {
    display: flex;
}

.hover-grey {
    &:hover {
        background: rgba(200, 216, 255, 0.05);
        cursor: pointer;
    }
}

.homepage-toggle {
    height: 260px;
    overflow-y: scroll;
}

.homepage-toggle::-webkit-scrollbar {
    display: none;
}