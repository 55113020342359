@import "../../../../styles/variables";

.options-table {
  &__action {
    width: 100px;
  }
  &__price {
    width: 200px;
  }
}
