@import "../../../styles/variables";

.product {
  &-button__box {
    background: $black13;
    border-radius: 5px;
  }
}

.filter {
  &-icon {
    margin-left: 20rem;
  }
  &-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.table {
  &-status {
    background: $green1;
    font-weight: 600;
  }
  &-Active {
    background: $green1;
    font-weight: 600;
  }&-Draft {
    background: red;
    font-weight: 600;
  }
  &-rows {
    p {
      font-size: $font2 !important;
    }
  }
}
