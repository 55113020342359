h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  font-weight: normal;
  // text-transform:uppercase;
}

h1 {
  font-size: 1.875rem;
  letter-spacing: 0.02em;
}
h2 {
  font-size: 1.625rem;
  letter-spacing: 0.02em;
  font-weight: 600;
}
h3 {
  font-size: 1.375rem;
  letter-spacing: 0.02em;
}
h4 {
  font-size: 1.25rem;
  letter-spacing: 0.02em;
}
h5 {
  font-size: 1.125rem;
  letter-spacing: 0.02em;
}
h6 {
  font-size: 1rem;
  letter-spacing: 0.02em;
}
p {
  font-size: 0.875rem;
  letter-spacing: 0.01em;
}
small {
  font-size: 0.75rem;
  letter-spacing: 0.02em;
}

.ex-small {
  font-size: 0.65rem;
  letter-spacing: 0.02em;
}

img,
svg {
  // pointer-events: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@media screen and (max-width: 576px) {
}
