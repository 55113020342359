.recharts-tooltip-wrapper {
  outline: none;
  opacity: 1;
  .chart-tooltip {
    background: linear-gradient(
      303.01deg,
      rgba(89, 97, 248, 0.2) -4.4%,
      rgba(242, 217, 247, 0.2) 111.29%
    );
    box-shadow: 0px 12.0293px 60.1466px rgba(26, 32, 44, 0.09);
    border-radius: 12.0293px;
    padding: 1rem;
    opacity: 1;
    h6,
    small {
      font-family: "Inter", sans-serif !important;
    }
  }
}
