@import "../../../../styles/global";

.img-container {
  background-color: #1a1932;
}

.folder-icon-view-hovering {
  background-color: #1a1932;
}

.red-menu-item {
  color: #fc756e;
}

.text-1 {
  font-size: $font1;
}

.text-3 {
  font-size: $font3;
}
