@import "../../styles/variables";

.sidenav {
  .logo {
    margin: 15px 0 20px;
  }

  .list {
    &-icon {
      min-width: auto;
      padding: 8px;

      img {
        height: 22px;
      }
    }

    transition: all 0.1s ease-in;

    &-item {
      border-radius: 10px;
      padding: 0 8px;
      margin-bottom: 6px;
      background: transparent;
      color: $grey3;
      transition: all 0.1s ease-in;

      &:hover {
        transition: all 0.1s ease-in;
        background: linear-gradient(303.01deg,
            rgba(89, 97, 248, 0.25) -4.4%,
            rgba(242, 217, 247, 0.25) 111.29%);
        color: $white1;
      }
    }

    &-text {
      span {
        font-weight: 500;
        font-size: $font3;
      }
    }
  }

  a {
    text-decoration: none;
    color: $white1;
  }

  .active {
    .list {
      &-item {
        background: linear-gradient(303.01deg,
            rgba(89, 97, 248, 0.25) -4.4%,
            rgba(242, 217, 247, 0.25) 111.29%);
        color: $white1;
      }
    }
  }

  .accordion-body {
    background: linear-gradient(303.01deg,
        rgba(89, 97, 248, 0.1) -4.4%,
        rgba(242, 217, 247, 0.1) 111.29%);
    border-radius: 10px;
    margin-top: -44px;
    padding-top: 44px;
    margin-bottom: 6px;
  }

  .accordion-item {
    border: 0;
  }
}