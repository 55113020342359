.onBoardDrop {
    .css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        background: none !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none !important;
        border-bottom: 1px solid #C8D8FF !important;
        border-radius: 0px !important;
    }
}

.stepFour {
    height: 100vh;

    img {
        margin-top: 8rem !important;
    }
}

.h-vh-100 {
    height: 100vh !important;
}

.onBoardInput-2 {
    .css-12stv8r-MuiInputBase-root-MuiOutlinedInput-root {
        background: none !important;
        border-bottom: 1px solid #C8D8FF !important;
        border-radius: 0px !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

.mb-rem-5 {
    margin-bottom: 5rem;
}

.rotating-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.rotating-text-wrapper h3 {
    margin: 0;
    padding: 0.3em;
    color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    animation-duration: 8s;
    animation-iteration-count: infinite;
    opacity: 0;
}

.rotating-text-wrapper h3:nth-child(1) {
    animation-name: rotating-text-1;
    background: linear-gradient(303.01deg, #5961f8 -4.4%, #f2d9f7 111.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}


@keyframes rotating-text-1 {
    0% {
        transform: translateY(200%);
    }

    25% {
        transform: translateY(100%);
        opacity: 1;
    }

    26% {
        opacity: 0;
    }
}

.rotating-text-wrapper h3:nth-child(2) {
    background: linear-gradient(303.01deg, #5961f8 -4.4%, #f2d9f7 111.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation-name: rotating-text-2;
}

@keyframes rotating-text-2 {
    25% {
        transform: translateY(100%);
        opacity: 0;
    }

    50% {
        transform: translateY(0);
        opacity: 1;
    }

    51% {
        opacity: 0;
    }
}

.rotating-text-wrapper h3:nth-child(3) {
    background: linear-gradient(303.01deg, #5961f8 -4.4%, #f2d9f7 111.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation-name: rotating-text-3;
}

@keyframes rotating-text-3 {
    50% {
        transform: translateY(0);
        opacity: 0;
    }

    75% {
        transform: translateY(-100%);
        opacity: 1;
    }

    76% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.rotating-text-wrapper h3:nth-child(4) {
    background: linear-gradient(303.01deg, #5961f8 -4.4%, #f2d9f7 111.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    animation-name: rotating-text-4;
}

@keyframes rotating-text-4 {
    75% {
        transform: translateY(0);
        opacity: 0;
    }

    100% {
        transform: translateY(-100%);
        opacity: 1;
    }
}