.onBoardInput {
    background: none !important;

    input {
        border: none !important;
        border-bottom: 1px solid #C8D8FF !important;
        border-radius: 0px !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 0px;
    }

    .css-19ccdyp-MuiInputBase-root-MuiOutlinedInput-root {
        background: none !important;
    }
}