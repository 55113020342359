@import "../../styles/global";

.app-navbar {
  background: $black2 !important;
  box-shadow: none !important;
  &__toggle {
    // border: 1px solid $grey1 !important;
    margin-left: -2rem;
  }
  &__profile {
    border-radius: 5px !important;
  }
}
