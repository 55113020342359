@import "../../../../styles/variables";

.variants {
  &-inputs {
    .MuiOutlinedInput-root {
      padding-right: 6px !important;
    }
    .MuiInputAdornment-outlined {
      p {
        font-size: 13px !important;
        color: $lightBlue !important;
        background: $black10 !important;
        padding: 3px 8px;
        border-radius: 4px;
      }
    }
  }
}

.table-container {
  width: 100%;
  margin: auto;

  overflow-x: auto;

  table {
    position: relative;
    table-layout: auto;
    width: 100%;

    tr > th:first-child,
    tr > td:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }

    tr > td:first-child,
    tr > th:first-child {
      background-color: #14122b;
    }

    tr:hover > td:first-child,
    tr:hover > th:first-child {
      background-color: #211f3c;
    }
    thead {
      tr:first-child > th:first-child {
        background-color: #1a1932;
      }
    }
  }
}
