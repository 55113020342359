.dashboard-container {
    max-height: 90vh;
    overflow-y: auto;
    padding: 0;
}

.dashboard-container::-webkit-scrollbar {
    display: none;
}

.dashboard {
    background-image: url(../../assets/dashboard/worldMap.png);
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.dashBoard-box {
    padding: 2rem 0rem 2rem 4rem;
}

.w-10 {
    width: 40% !important;
}

.fs-3 {
    font-size: 2.7rem !important;
}

.link-blue-gradient {
    background: linear-gradient(303.01deg, #5961f8 -4.4%, #f2d9f7 111.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    border-bottom: 2px solid #9da2f4;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0.4rem;
}

.live-visitor {
    .css-hyxlzm {
        color: #E97676 !important;
    }
}

.ex-chip {
    border-radius: 2rem;
}

.bg-button-2 {
    border: 0.754px solid rgba(126, 132, 188, 0.40) !important;
    background: linear-gradient(90deg, rgba(104, 108, 248, 0.06) -4.4%, rgba(236, 171, 241, 0.06) 111.29%);
    backdrop-filter: blur(4px);
}

.bg-button-3 {
    border: 0.754px solid rgba(126, 132, 188, 0.40) !important;
    background: linear-gradient(180deg, rgba(104, 108, 248, 0.06) 4.49%, rgba(236, 171, 241, 0.06) 100%);
    backdrop-filter: blur(4px);
}

.bg-button-4 {
    background: linear-gradient(90deg, rgba(236, 171, 241, 0.06) 4.4%, rgba(104, 108, 248, 0.06) 111.29%);
    backdrop-filter: blur(4px);
}

.bg-button-5 {
    background: #1E1B3B;
    backdrop-filter: blur(4px);
}

.rocket-img {
    position: absolute;
    bottom: -30px;
    right: -50px;
}

.rocket-img-2 {
    position: absolute;
    bottom: -50px;
    right: 0;
}

.rocket-button {
    position: absolute;
    top: 120px;
    margin-left: 1rem;
    z-index: 1;
}

.rocket-button-3 {
    position: absolute;
    top: 112px;
    z-index: 1;
}

.small-chip {
    .MuiChip-filled {
        height: 22px;
        border: 1px solid #676893;
    }
}

.image-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    white-space: nowrap;
}

.image-container::-webkit-scrollbar {
    display: none;
}