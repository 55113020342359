.device-gradient {
    background: linear-gradient(165.69deg, #7E84BC 4.49%, #434663 113.71%);
    height: 8px;
    border-radius: 50%;
}

.device-gradient-2 {
    background: linear-gradient(165.69deg, #7E84BC 4.49%, #434663 113.71%);
    height: 8px;
    border-radius: 10px;
}

.iFrame {
    .__className_4301c5 {
        display: none;
    }
}

.rounded-19 {
    border-radius: 1.1rem;
}

.themeArrow {
    padding: 0.6rem;
    border-radius: 50%;
    z-index: 1204;
}

.themeHandler {
    position: fixed;
    bottom: 2rem;
    left: 19rem;
}

.themeHandler-0 {
    position: fixed;
    bottom: 2rem;
    left: 0.7rem;
}