.signup {
  min-height: 100vh;
  background: url("../../../assets/icons/signup.png");
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
  &-row {
    height: 100vh;
  }
  &-image {
    // height: ;
  }
  &-box {
    background: radial-gradient(
          145.46% 122.03% at 88.28% 10.25%,
          rgba(222, 164, 242, 0.1) 0%,
          rgba(0, 0, 0, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          93.02% 80.9% at 22.44% 86.73%,
          rgba(110, 111, 248, 0.1) 0%,
          rgba(110, 111, 248, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(
        131.02deg,
        rgba(104, 108, 248, 0.059) 10.34%,
        rgba(236, 171, 241, 0.059) 90.18%
      ),
      #060519;

    width: 450px;
  }
}

@media screen and (max-width: 576px) {
  .signup {
    &-box {
      width: 300px;
    }
  }
}
