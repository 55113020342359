@import "../../../styles/variables";

.bottom-buttons {
  background: linear-gradient(180deg, rgba(17, 15, 38, 0) 0%, #110f26 47.98%);
  position: sticky;
  bottom: 0;
  left: 0;
  margin-bottom: -24px;
}

.tags-checkbox {
  .MuiTypography-root {
    font-size: $font2 !important;
    color: $lightBlue !important;
  }
}

.step-label {
  cursor: default;
}

.step-label .Mui-completed {
  color: rgba(255, 255, 255, 0.7);
}

.step-label.enabled .Mui-completed {
  color: #c8d8ff !important;
}

.step-label .Mui-active {
  color: #c8d8ff !important;
}
