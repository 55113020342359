@import "../../../styles/variables";

.discount-gradient-inputs {
  .MuiInputAdornment-outlined {
    p {
      font-size: 13px !important;
      color: $white1 !important;
      background: linear-gradient(
        165.69deg,
        rgba(104, 108, 248, 0.7) 4.49%,
        rgba(236, 171, 241, 0.7) 113.71%
      ) !important;
      padding: 3px 8px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .MuiOutlinedInput-root {
    padding-right: 6px !important;
  }
}

.discount-inputs {
  .MuiInputAdornment-outlined {
    p {
      font-size: 13px !important;
      color: $lightBlue !important;
      background: $black10 !important;
      padding: 3px 8px;
      border-radius: 4px;
    }
  }
  .MuiOutlinedInput-root {
    padding-right: 6px !important;
  }
}

.discount-inputs-two {
  .MuiInputAdornment-outlined {
    span {
      background: $black10 !important;
      padding: 1px 8px;
      border-radius: 4px;
      p {
        font-size: 13px !important;
        color: $lightBlue !important;
      }
    }
  }
  .MuiOutlinedInput-root {
    padding-right: 6px !important;
  }
}
