@import "../../styles/global";

.small-upload-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #38395c;
  padding: 4.5px 14px;
  border-radius: 5px;
  gap: 8px;
  // cursor: pointer;
  position: relative;
  background-color: $black9;

  &.focus {
    box-shadow: inset 0px 0px 0px 2px $lightBlue !important;
  }

  .cancel-button-container {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);

    button {
      border-radius: 50%;
      width: 4px;
      height: 4px;
      display: grid;
      place-content: center;
      background: $lightBlue;
    }
  }

  .svg {
    path {
      fill: #5c6d8e;
    }
  }

  &:focus-visible {
    outline: none;
  }

  .icon-placeholder {
    border: 1px dashed #38395c;
    border-radius: 4px;
    display: grid;
    place-items: center;
    padding: 2px;

    .icon {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }
}

.large-upload-container {
  padding: 0.5rem;
  display: grid;
  gap: 5px;
  background-color: $black9;
  min-height: 150px;
  border: 1px dashed #38395c;
  position: relative;
  cursor: pointer;

  &.focus {
    box-shadow: inset 0px 0px 0px 2px $lightBlue !important;
  }

  .upload-text {
    display: grid;
    gap: 5px;
    place-content: center;
  }

  .upload-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }

  &:focus-visible {
    outline: none;
  }

  .cancel-button-container {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    // z-index: 10;

    button {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      display: grid;
      place-content: center;
      background: $lightBlue;
    }
  }

  .media {
    width: 100%;
    object-fit: contain;
    height: 150px;
  }
}

.round-upload-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  border-width: 2;
  border-radius: 50%;
  border-color: #38395c;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: "border .24s ease-in-out";
  cursor: pointer;
  justify-content: center;
  background-color: #1a1932;

  &.focus {
    border-color: #2196f3 !important;
  }
}
