@import "../../../styles/variables";

.shipping {
  .MuiInputAdornment-outlined {
    p {
      font-size: 13px !important;
      color: $lightBlue !important;
      background: $black10 !important;
      padding: 3px 8px;
      border-radius: 4px;
    }
  }
  &-inputs {
    .MuiOutlinedInput-root {
      padding-right: 6px !important;
    }
  }
}
