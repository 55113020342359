.statusBoxWidth {
    width: 90px !important;
    display: flex;
    justify-content: center;
}

.closeModal {
    position: absolute;
    top: 20px;
    right: 20px;
}