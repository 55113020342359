@import "../../../../styles/variables";

.add-product-padding {
  margin-left: 30rem;
}

.feature-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.feature-buttons-fixed {
  background: linear-gradient(180deg, rgba(17, 15, 38, 0) 0%, #110f26 47.98%);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
