@import "../../../styles/variables.scss";

.imagePreview {
    border: 2px dashed #343E50;
    width: 65px;
    height: 65px;
    border-radius: 50%;

    input {
        width: 65px;
        height: 65px;
        display: none;
    }

    img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        position: absolute;
        top: 238px;
    }
}