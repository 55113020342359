@import '../../../../styles/variables';

.product-media {
  .media {
    height: 20rem !important;
  }
}

.status-container {
  padding-top: 0 !important;
}

.status-title {
  font-size: 14px !important;
  font-weight: normal !important;
  margin-bottom: 12px !important;
}

.status-action {
  margin-top: 12px !important;
}

.linked-value input {
  font-size: 16px !important;
  -webkit-text-fill-color: #658ded !important;
  color: #658ded !important;
}

.linked-value .linked-title {
  color: #96abde !important;
  font-size: 16px !important;
}

.productInfo {
  &-inputs {
    .MuiOutlinedInput-root {
      padding-right: 8px !important;
    }
  }

  &-toggle {
    &__active.Mui-selected {
      background: $green2 !important;

      p {
        color: $black9 !important;
      }
    }

    &__draft.Mui-selected {
      background: $red3 !important;

      p {
        color: $white1 !important;
      }
    }

    p {
      text-transform: capitalize !important;
    }

    .MuiToggleButton-root {
      line-height: 1.4 !important;
    }
  }

  &-inputs {
    .MuiOutlinedInput-root {
      padding-right: 6px !important;
      padding-left: 6px !important;
    }

    .MuiInputAdornment-outlined {
      p {
        font-size: 13px !important;
        color: #c8d8ff !important;
        background: #27283f !important;
        padding: 2px 10px;
        border-radius: 4px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .MuiOutlinedInput-input {
      text-align: center !important;
    }
  }

  .primary-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      303.01deg,
      rgba(89, 97, 248, 0.2) -4.4%,
      rgba(242, 217, 247, 0.2) 111.29%
    );
    border-radius: 5px;
    border: 0;
    color: $white1;
    text-decoration: none;
    min-width: max-content;
  }
}

.mediaToolCheckBox {
  position: relative !important;
  top: 55px;
}

.mediaToolDot {
  position: relative !important;
  bottom: 35px;
}

.mediaToolCheckBox2 {
  position: relative !important;
}

.icon-fontSize {
  font-size: 16px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.grid-item {
  padding: 5px;
  text-align: center;
  margin-top: -60px !important;
  margin-bottom: -30px !important;
}

.uploadImageheight {
  margin-top: -40px !important;
  height: 340px !important;
}

.mr-1 {
  margin-left: 0.4rem !important;
}

.mr-2 {
  margin-right: 0.8rem !important;
}

.mr-3 {
  margin-right: 1.2rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.pl-3 {
  padding-right: 1.5rem !important;
}

.backgroundDisable {
  .MuiOutlinedInput-root {
    background: #25243b !important;
  }
}
