@import "../../../styles/variables";

.create-buttons {
  background: linear-gradient(180deg, rgba(17, 15, 38, 0) 0%, #110f26 47.98%);
  position: sticky;
  bottom: 0;
  left: 0;
  margin-bottom: -24px;

  width: 100%;
}
.user-group {
  min-height: calc(100vh - 102px);
}

.add-product-padding {
  margin-left: 30rem;
}
