.playVideo {
    position: absolute !important;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
}

.videoLength {
    position: absolute;
    bottom: 0.7rem;
    right: 1rem;
}