@import "../../styles/variables";

.settingNav {
    .logo {
        margin: 24px 0;
    }

    .list {

        &-icon {
            min-width: auto;
            padding: 8px;

            img {
                height: 21px;
            }
        }

        transition: all 0.1s ease-in;

        &-item {
            padding: 3px 3px 3px 2px;
            background: transparent;
            color: $lightBlue;
            transition: all 0.1s ease-in;
            margin-top: 8px;
            opacity: 0.4;

            &:hover {
                transition: all 0.1s ease-in;
                background: rgba(255, 255, 255, 0.03);
                border-radius: 8px;
                opacity: 1;
            }
        }

        &-text {
            span {
                font-weight: 500;
                font-size: 14px;
            }
        }
    }

    a {
        text-decoration: none;
        color: $white1;
    }

    .active {
        .list {
            &-item {
                border-image: linear-gradient(#F2D9F7, #5961F8);
                border-image-slice: 1;
                opacity: 1;
                background: rgba(255, 255, 255, 0.05);
                padding-block: 2px;
                border-radius: 8px;
            }
        }

        .settingActive {
            background: linear-gradient(#F2D9F7, #5961F8);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 20px 3px 20px 2px;
            position: absolute;
            left: 0;
        }
    }

    .accordion-body {
        border-radius: 10px;
        margin-top: -44px;
        padding-top: 44px;
        margin-bottom: 6px;
    }

    .accordion-item {
        border: 0;
    }
}

.fs-10 {
    font-size: 11px !important;
}

.borderRadius-8 {
    border-radius: 0px 0px 8px 8px;
}

.borderRadiusTop-8 {
    border-radius: 8px 8px 0px 0px;
}